import Home from './pages/Home';
import "./styles/index.css"
function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
