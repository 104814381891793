import React from 'react'
import styled from 'styled-components'
import Logo from '../../../assets/images/logo.svg'

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  @media (max-width: 800px) {
    padding: 10px 0px;
  }
`;

const StyledButton = styled.button`
  font-size: 17px;
  padding: 10px 25px;
  border-radius: 9999px;
  background-image: linear-gradient(180deg, #22B3F1 0%, rgba(18, 224, 237, 0.21) 100%);
  border: 2px solid #22B3F1;
  border-bottom: 4px solid #22B3F1;
  box-shadow: 0px 1px 6px 0px #22B3F1;
  transform: translate(0, -3px);
  transition: 0.2s;
  transition-timing-function: linear;
  color: white;
  -webkit-text-stroke: 1px #FFFFFF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 40;

  &:focus {
    outline: none;
  }

  &:active {
    transform: translate(0, 0);
    border-bottom: 2px solid #22B3F1;
  }
`;


const StyledConnectButton = styled.button`
  background-color: #FFFFFF;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 17px;
  color: inherit;
  positon: relative;
  z-index: 40;

  :active {
    transform: translateY(5px);
    box-shadow: 0px 0px 0px 0px red;
  }
`

const Image = styled.img`
  width: 42.5px;
  z-index: 10;
`

function Navbar() {
  return (
    <Container>
      <Image src={Logo} />
    </Container>
  )
}

export default Navbar