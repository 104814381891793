import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from 'styled-components/macro';
import CountUp from "react-countup";
import { ReactComponent as DiscordI } from '../../../assets/images/discord.svg'
import { ReactComponent as TwitterI } from '../../../assets/images/twitter-safe.svg'
import { ReactComponent as GithubI } from '../../../assets/images/github.svg'


const DiscordIcon = styled(DiscordI) <{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + 'px' : '32px')};
  width: ${({ size }) => (size ? size + 'px' : '32px')};
  fill: ${({ fill }) => fill ?? '#FFFFFF'};
  opacity: 1;
`


export const TwitterIcon = styled(TwitterI) <{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + 'px' : '32px')};
  width: ${({ size }) => (size ? size + 'px' : '32px')};
  fill: ${({ fill }) => fill ?? '#FFFFFF'};
  opacity: 1;
`

export const GithubIcon = styled(GithubI) <{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + 'px' : '32px')};
  width: ${({ size }) => (size ? size + 'px' : '32px')};
  fill: ${({ fill }) => fill ?? '#FFFFFF'};
  opacity: 1;`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 90vh;
  width: 100%;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 100%;
`;

const Canva = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: none;
  filter: blur(1px);
  z-index: 2;
`;

const animate = keyframes`
  0%, 100% {
    clip-path: polygon(
      0% 36%,       
      17% 30%,      
      34% 50%,
      56% 61%,
      69% 62%,
      86% 60%,
      100% 51%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 59%,
      15% 55%,
      34% 56%,
      40% 52%,
      67% 40%,
      84% 35%,
      100% 36%,
      100% 100%,
      0% 100%
    );
  }
`;



const StyledH2 = styled.h2<{ stroke?: boolean; animated?: boolean }>`
  font-family: 'Lilita One';
  font-size: 8em;
  position: absolute;
  transform: translate(0%, -50%);
  top: calc(50% - 0);
  
  color: ${props => (props.stroke ? 'transparent' : 'linear-gradient(180deg, #22B3F1 0%, rgba(18, 224, 237, 0.21) 100%)')};

  ${props =>
    props.stroke &&
    `
    -webkit-text-stroke: 3px #FFFFFF;
    font-weight: 500;
  `}

  ${props =>
    props.animated &&
    css`
      animation: ${animate} 4s ease-in-out infinite;
      font-weight: 10;
      background: linear-gradient(180deg, #22B3F1 0%, rgba(18, 224, 237, 0.21) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    `}

  @media (max-width: 600px) {
    font-size: 4.5em;
  }
`;

const StyledH3 = styled.h3<{ stroke?: boolean; animated?: boolean }>`
  font-family: 'Lilita One';
  font-size: 3em;
  position: absolute;
  transform: translate(0%, -50%);
  bottom: -100%;
  text-align: center;
  
  color: ${props => (props.stroke ? 'transparent' : 'linear-gradient(180deg, #22B3F1 0%, rgba(18, 224, 237, 0.21) 100%)')};

  ${props =>
    props.stroke &&
    `
    -webkit-text-stroke: 3px #FFFFFF;
    font-weight: 500;
  `}

  ${props =>
    props.animated &&
    css`
      animation: ${animate} 4s ease-in-out infinite;
      font-weight: 10;
      background: linear-gradient(180deg, #22B3F1 0%, rgba(18, 224, 237, 0.21) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    `}

  @media (max-width: 600px) {
    font-size: 2em;
  }
`;

const StyledH3v2 = styled.h3`
  font-family: 'Lilita One';
  font-size: 3em;
  display: flex;
  justify-content: center;
  text-align: center;
  background: red;
  color: linear-gradient(180deg, #22B3F1 0%, rgba(18, 224, 237, 0.21) 100%);
  background: linear-gradient(180deg, #22B3F1 0%, rgba(18, 224, 237, 0.21) 100%);
  -webkit-text-stroke: 3px #FFFFFF;
  font-weight: 500;
  text-fill-color: transparent;

  @media (max-width: 600px) {
    font-size: 2em;
  }
`;

const ContentText = styled.div`
  display: flex;
  width: 60%;
  justify-content: center;
  font-size: 1.5em;
  heigth: Hug(50px);
  text-align: center;
  @media (max-width: 750px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    font-size: 1em;
    width: 95%;
  }
`;

const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-top: 0em;
  position: relative;
  z-index: 40;
  
`;

const SocialIcon = styled.a`
  font-size: 2em;
  color: #FFFFFF;

  &:hover {
    color: #FFFFFF;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  margin-top: 100px;
  @media (max-width: 600px) {
    margin-top: 110px;
  }
`;
const Text = styled.p`
  color: #FFFFFF;
  font-family: 'Carlito', sans-serif;
  &:hover {
    color: #FFFFFF;
  }
`;

const NumberContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;
  @media (max-width: 940px) {x
    width: 85%;
  }
  @media (max-width: 800px) {
    width: 95%;
  }
  @media (max-width: 600px) {
    justify-content: space-between;
    padding : 0px 0px;
    width: 100%;
    margin-bottom: 70px;
  }
`;

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(50% - 20px);
  margin-bottom: 1em;
  @media (min-width: 601px) {
    width: auto;
    margin-bottom: 0;
  }
`;

const Number = styled.div`
  font-size: 4em;
  color: #ffffff;
  @media (max-width: 1180px) {
    font-size: 3em;
  }
  @media (max-width: 940px) {
    font-size: 2.5em;
  }
  @media (max-width: 600px) {
    font-size: 2em;
  }
`;

const NumberText = styled.p`
  color: #ffffff;
  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;

const toggle__animation = keyframes`
0%, 100% {
  transform: translateY(-10px) rotateX(0deg) rotateY(0deg);
}

50% {
  transform: translateY(0px) rotateX(0deg) rotateY(0deg);
}
`;

const Title = styled.h2`
display: flex;
justify-content: center;
width: 100%;
color: transparent;
font-size: 27px;
height: 2em;
transform-style: preserve-3d;
font-family: 'Carlito', sans-serif;
perspective: 500px;
animation: ${toggle__animation} 3s infinite;
font-family: 'Lilita One';
font-size: 3em;
text-align: center;
color: linear-gradient(180deg, #22B3F1 0%, rgba(18, 224, 237, 0.21) 100%);
background: linear-gradient(180deg, #22B3F1 0%, rgba(18, 224, 237, 0.21) 100%);
-webkit-text-stroke: 1px #FFFFFF;
-webkit-background-clip: text;
background-clip: text;
font-weight: 100;

@media (max-width: 600px) {
  font-size: 2em;
}
`;

function formatNumber(number: number): string {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "k";
  } else {
    return number.toString();
  }
}

interface CustomCountUpProps {
  end: number;
}

const CustomCountUp: React.FC<CustomCountUpProps> = ({ end }) => {
  const [displayNumber, setDisplayNumber] = useState('');

  useEffect(() => {
    setDisplayNumber(formatNumber(end));
  }, [end]);

  return (
    <CountUp end={end} duration={5} onEnd={() => setDisplayNumber(formatNumber(end))}>
      {({ countUpRef }) => <span ref={countUpRef}>{displayNumber}</span>}
    </CountUp>
  );
};

interface LandingHomeProps {
  targetNumbers: number[];
}

const LandingHome: React.FC<LandingHomeProps> = ({ targetNumbers }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const mouse = { x: 0, y: 0, radius: 30 };
  const [numbers, setNumbers] = useState(
    targetNumbers.map((target) => ({ current: 0, target }))
  );
  const Value = ["Trade Volume", "All Time Trades", "Total Value Locked", "Fees Generated"]

  useEffect(() => {
    const canvas = canvasRef.current!;
    const ctx = canvas.getContext("2d")!;
  
    let w = (canvas.width = window.innerWidth);
    let h = (canvas.height = window.innerHeight);
    const length = 90; // Réduisez cette valeur pour diminuer la densité des bulles
    const speeds = [...Array(length)].map(() => Math.random() * (0.8 - 0.1) + 0.2); // Vitesse réduite ici
    const radii = [...Array(length)].map(() => Math.random() * (10 - 3) + 2); // Taille des bulles augmentée ici
    const lastPos = [...Array(length)].map(() => ({ x: Math.random() * w, y: Math.random() * h }));
    function draw() {
      // Cursor
      if (mouse.x > 0 && mouse.y > 0) {
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#FFFFFF";
        ctx.beginPath();
        ctx.arc(mouse.x, mouse.y, mouse.radius / 2, 0, Math.PI * 2);
        ctx.closePath();
        ctx.stroke();
      }
  
      // Particles
      for (let i = 0; i < length; i++) {
        let x = lastPos[i].x;
        let y = lastPos[i].y;
        const radius = radii[i];
  
        const dx = mouse.x - x;
        const dy = mouse.y - y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const forceDirectionX = dx / distance;
        const forceDirectionY = dy / distance;
        const maxDistance = mouse.radius;
        const force = maxDistance - distance;
        const directionX = forceDirectionX * force;
        const directionY = forceDirectionY * force;
  
        if (distance < mouse.radius) {
          x -= directionX;
          y -= directionY;
        }
  
        ctx.fillStyle = "rgba(236, 241, 243, 0.4)";
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
  
        lastPos[i].y = (y - speeds[i] + h) % h;
      }
    }
  
    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      draw();
  
      requestAnimationFrame(animate);
    }
  
    animate();
  
    window.addEventListener("resize", () => {
      w = canvas.width = window.innerWidth;
      h = canvas.height = window.innerHeight;
      lastPos.map(() => ({
        x: Math.random() * w,
        y: Math.random() * h,
      }));
    });
  
    canvas.addEventListener("mousemove", (e) => {
      const rect = canvas.getBoundingClientRect();
      mouse.x = e.clientX - rect.left;
      mouse.y = e.clientY - rect.top;
    });
  
    canvas.addEventListener("mouseleave", () => {
      mouse.x = -1000;
      mouse.y = -1000;
    });
  }, []);
  

  return (
    <>
      <Canva ref={canvasRef} />
      <Container>
        <SubContainer>
          <StyledH2 stroke>Zplash</StyledH2>
          {<StyledH2 animated>Zplash</StyledH2>}
          <Section>
            <ContentText>
              <Text>
                Swap earn and provide liquidity on the leading decentralized protocol
                built on zkSync.
              </Text>
            </ContentText>
            <SocialMediaContainer>
              <SocialIcon href="https://twitter.com/zplashlabs" target="_blank">
                <TwitterIcon />
              </SocialIcon>
              <SocialIcon href="https://discord.gg" target="_blank">
                <DiscordIcon />
              </SocialIcon>
              <SocialIcon href="https://github.com/Zplash-Labs" target="_blank">
                <GithubIcon />
              </SocialIcon>
            </SocialMediaContainer>
          </Section>
        </SubContainer>
        <Title>
        Coming Soon
        </Title>
      </Container>
    </>
  );
};

export default LandingHome;

/*@keyframes toggle__animation {
  0%, 100% {
    transform: translateY(-10px) rotateX(15deg) rotateY(-20deg);
  }

  50% {
    transform: translateY(0px) rotateX(15deg) rotateY(-20deg);
  }
}*/