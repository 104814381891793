import LandingHome from "./LandingHome";
import styled from 'styled-components/macro'
import Fond from "../../assets/images/FondLanding.svg"
import Navbar from "./navbar";

const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding: 5px 50px 0px 50px;
  background-image: url(${Fond});
  background-repeat: no-repeat;
  background-size: cover;
`


const Home = () => {
  return (
    <>
      <Container>
        <Navbar />
        <LandingHome targetNumbers={[1000, 2000, 3000000, 400]} />
      </Container>
    </>
  )
}

export default Home;